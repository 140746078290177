import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderListRest,
  userWiseTarget,
  getProducts,
  targetUpdate,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Button } from "react-bootstrap";
import TargetAction from "./TargetAction";

const UserWiseTargate = () => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");
  const [page, setPage] = useState(1);

  useEffect(() =>{
    dispatch(getOrderListRest());
    dispatch(userWiseTarget({
        "distributor" : userid
    }))
    dispatch(getProducts())
  },[])
  const {
    userWiseTargetList,
    userWiseTargetMsg,
    getProductsList,
  } = useSelector((state) => state.commonReducer);

// Find matching items based _id
      const matchingItems = getProductsList?.map(products => {
        const matchingItem2 = !!getProductsList && getProductsList?.map((subProduct) => subProduct?.targets?.find(item2 => item2._id === products._id))
  
        if (matchingItem2) {
          return {
            itemName: products.itemName
          };
        }
        return null;
      })
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <table className="table table-scrolling">
        <table className="table table-scrolling">
  <thead>
    <tr>
      <th scope="col">Sr</th>
      <th scope="col">Date</th>
      {!!matchingItems &&
        matchingItems.map((data, index) => (
          <th scope="col" key={index}>
            {data.itemName}
          </th>
        ))}
      <th scope="col">Total Target</th>
    </tr>
  </thead>
  <tbody>
    {!!userWiseTargetList &&
        userWiseTargetList.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}.</td>
            <td>
              {moment(data.date).format("DD/MM/YYYY")}
              <br />
              {data.month === moment().month() + 1 && data.year === moment().year() && (
                <TargetAction
                  month={data.month}
                  year={data.year}
                  targetId={data._id}
                  api={targetUpdate}
                  title="Update"
                  includedTargets={data.includedTargets}
                  target={data.targets}
                />
              )}
            </td>
            {!!getProductsList &&
              getProductsList.map((matchingItem, columnIndex) => {
                const itemData = data?.targets?.find(
                  (target) =>  target?.itemId === matchingItem?._id
                );
                return (
                  <td key={columnIndex}>
                    {itemData ? itemData.quantity : 0}
                  </td>
                );
              })}
            <td>{data.total}</td>
          </tr>
        ))}
    </tbody>
  </table>
          {/* <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Date</th>
              {
                !!matchingItems && matchingItems?.map((data, index) => {
                    return(
                        <th scope="col" key={index}>{data?.itemName}</th>
                    )
                })
              }
              <th scope="col">Total Target</th>
            </tr> 
          </thead>
          <tbody>
            {!!userWiseTargetList &&
              userWiseTargetList.map((data, index) => {
                return (
                  <tr key={index}>
                   <td>{index + 1}.</td>
                   <td>
                    {moment(data?.date).format("DD/MM/YYYY")}<br/>
                    {(data?.month === moment().month() + 1) && <TargetAction month={data?.month} year={data?.year} targetId={data?._id} api={targetUpdate} title="Update" includedTargets={data?.includedTargets} target={ data?.targets}/>}
                   </td>
                    {
                        data?.targets?.map((product, index) => {
                            return(
                                <td key={index}>{product?.quantity}</td>
                            )
                        })
                    }
                    <td>
                      {data?.total}
                    </td>
                  </tr>
                );
              })}
          </tbody> */}
        </table>
        {!!userWiseTargetMsg &&
        userWiseTargetMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  userWiseTarget({
                    page: page + 1,
                    "distributor" : userid
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default UserWiseTargate;
