import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  allApi,
  messages,
  postApi,
  postApiFile,
  postApiProduct,
  postApiUploader,
  queryFileWithArray,
} from "../helpers/apiStructure";

const initialState = {
  careerList: [],
  distributorList: [],
  getBannersList:[],
  statesList: [],
  selectedDistributor: [],
  userfeedBackListBlank: [],
  getSalesPersonWithoutPageList: [],
  getDistrict: [],
  userQueryList: [],
  userfeedBackList: [],
  getSalesPerson: [],
  getSalesPersonMsg: [],
  getDispatchByList: [],
  getSoDispatcByList: [],
  getDispatchByListMsg: [],
  getOptionDispatchByList: [],
  getContractorAttendanceData:[],
  userWiseAttendanceList:[],
  getMyProfileData: [],
  getUsersOrderList: [],
  getOrderBlank: [],
  getLocalSaleOrderList:[],
  getLocalSaleBlank: [],
  getContractorData:[],
  getCategoryData:[],
  getGroupsData:[],
  getProductsList: [],
  getSelectedDistributorList: [],
  getSelectedDistributorListMsg: [],
  userWiseTargetList:[],
  userWiseTargetMsg:[],
  getSelectedSalesPersonList: [],
  createdASMPerson: [],
  getASMReportingList: [],
  getAsmProductReportingList: [],
  getMainReportsList: [],
  OrderdDeliverdMRpotList: [],
  getMainProductReportsList: [],
  getGroupWiseReportsList:[],
  getCatWiseReportsList:[],
  getCatWiseSaleList:[],
  getGroupWiseSaleList:[],
  getTrgtVsAchiMonthlyList:[],
  getSoProductReportsList: [],
  OdrdDlvrProdtRpotList: [],
  getSoMonthlyReportsList: [],
  userQueryListBlank: [],
  getDispatchbyMonthlyReportsList: [],
  getDispatchbyOrderMonthlyReportsList: [],
  getDispatchbyOrderProductReports: [],
  getDispatchbyProductReportsList: [],
  getTargetVsAchievementData:[],
  getASMPersons: [],
  getASMPersonsMsg: [],
  distributorListBlank: [],
  getContactListData: [],
  getClientReviewData:[],
  getIndustrialVisitData:[],
  userContactListBlank: [],
  careerListBlank: [],
  asmPersonsWithOutPage: [],
  dispatchByWithOutPage: [],
  getNewsLetterData:[],
  selectedDistributorWithoutPage: [],
  careerMsg: "",
  editMyProfileMsg: "",
  distributorAddMsg: "",
  createOrderMsg: "",
  feedBackUpdateMsg: "",
  complaintUpdateMsg: "",
  salesManUpdateMsg: "",
  createDispatchByMsg: "",
  feedBackMsg: "",
  salesManMsg: "",
  complaindMsg: "",
  distributorMsg: "",
  loginMsg: "",
  userUpdateMsg: "",
  distributorUpdateMsg: "",
  users: [],
  userName: "",
  addNewProductMsg: "",
  getClosingStockList: [],
};

export var baseUrl = "http://localhost:3000";

if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}
export const getCareerList = createAsyncThunk(
  "userCareerList",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/auth/career/list?department=${
        data?.department ? data?.department : ""
      }&page=${data?.page ? data?.page : "1"}&status=${
        data?.userStatus ? data?.userStatus : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&endDate=${
        data?.endDate ? data?.endDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getFeedBackList = createAsyncThunk(
  "getFeedBackList",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/auth/feedback/list?state=${
        data?.state ? data?.state : ""
      }&page=${data?.page ? data?.page : "1"}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const userComplaintList = createAsyncThunk(
  "userComplaintList",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/auth/complaint/list?state=${
        data?.state ? data?.state : ""
      }&page=${data?.page ? data?.page : "1"}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getDistributorList = createAsyncThunk(
  "getDistributorList",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/auth/distributor/list?state=${
        data?.state ? data?.state : ""
      }&page=${data?.page ? data?.page : "1"}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&exactParty=${
        data?.exactParty ? data?.exactParty : ""
      }&distance=${data?.distance ? data?.distance : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getStatesList = createAsyncThunk("getStatesList", async () => {
  const result = await allApi(`${baseUrl}/api/states`, "get");
  return result;
});
// department
export const getDepartmentDropDown = createAsyncThunk("getDepartmentDropDown", async () => {
  const result = await allApi(`${baseUrl}/api/department/list`, "get");
  return result;
});
export const getDepartments = createAsyncThunk("getDepartments",
  async (body) => {
    const result = await allApi(`${baseUrl}/api/department/mainlist`,"post", body);
    return result;
  }
);
export const addDepartments = createAsyncThunk("addDepartments",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/department/add`,"post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateDepartment = createAsyncThunk("updateDepartment",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/department/edit`,"post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getDepartmentWiseQ = createAsyncThunk("getDepartmentWiseQ", async (body) => {
  const result = await allApi(`${baseUrl}/api/department?id=${body?.id}`, "get");
  return result;
});
//senior state wise distributor
export const seniorStateDistributor = createAsyncThunk("seniorStateDistributor", async (body) => {
  const result = await allApi(`${baseUrl}/api/statedistributers`, "get");
  return result;
});
export const addSeniorStateDistributor = createAsyncThunk("addSeniorStateDistributor",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/statedistributer/add`,"post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateSeniorStateDistributor = createAsyncThunk("updateSeniorStateDistributor",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/statedistributer/update`,"post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
// department end
export const deleteCareerRecord = createAsyncThunk(
  "deleteCareerRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/career/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteDistributorRecord = createAsyncThunk(
  "deleteDistributorRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/distributor/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteComplaintRecord = createAsyncThunk(
  "deleteComplaintRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/complaint/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteFeedbackRecord = createAsyncThunk(
  "deleteFeedbackRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/feedback/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteDispatchBy = createAsyncThunk(
  "deleteDispatchBy",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/dispatch/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteSalesmanRecord = createAsyncThunk(
  "deleteSalesmanRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/salesman/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteProductRecord = createAsyncThunk(
  "deleteProductRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/auth/item/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteDistributorSelectedRecord = createAsyncThunk(
  "deleteSalesmanRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/distributor/selected/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getDistributor = createAsyncThunk("getDistributor", async () => {
  const result = await allApi(
    `${baseUrl}/api/distributor/selected/list`,
    "get"
  );
  return result;
});
export const getDistrictList = createAsyncThunk(
  "getDistrictList",
  async (state) => {
    const result = await allApi(`${baseUrl}/api/states/?state=${state}`, "get");
    return result;
  }
);
export const careerRegister = createAsyncThunk(
  "careerRegister",
  async (body) => {
    const result = await queryFileWithArray(
      `${baseUrl}/api/career`,
      "post",
      body,
      "Your form submited successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const distributorRegister = createAsyncThunk(
  "distributorRegister",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/distributor`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateSelectedDistributor = createAsyncThunk(
  "updateSelectedDistributor",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/distributor/selected/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const feedBackRegister = createAsyncThunk(
  "feedBackRegister",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/feedback`,
      "post",
      body,
      "We’re so happy to hear from you! Thank you for your valuable feedback."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//website Related API's
export const ContactForm = createAsyncThunk("ContactForm", async (body) => {
  const result = await postApi(`${baseUrl}/api/contact/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getContactList = createAsyncThunk(
  "getContactList",
  async (data) => {
    console.log("datawewr", data)
    const result = await allApi(
      `${baseUrl}/api/contact/list?page=${data?.page}`,
      "get"
    );
    return result;
  }
);
export const deletContactRecord = createAsyncThunk(
  "deletContactRecord",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/contact/remove/${data}`,
      "delete"
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const contactUpdate = createAsyncThunk("contactUpdate", async (body) => {
  const result = await postApi(`${baseUrl}/api/contact/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getClientReviewList = createAsyncThunk(
  "getClientReviewList",
  async () => {
    const result = await allApi(
      `${baseUrl}/api/review/list`,
      "post"
    );
    return result;
  }
);
export const addClientReview = createAsyncThunk("addClientReview", async (body) => {
  const result = await postApi(`${baseUrl}/api/review/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateClientReview = createAsyncThunk("updateClientReview", async (body) => {
  const result = await postApi(`${baseUrl}/api/review/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getNewsLetterList = createAsyncThunk(
  "getNewsLetterList",
  async () => {
    const result = await allApi(
      `${baseUrl}/api/newslatter/list`,
      "post"
    );
    return result;
  }
);
export const newsLetterDelete = createAsyncThunk(
  "newsLetterDelete",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/newslatter/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getIndustrialVisitList = createAsyncThunk("getIndustrialVisitList", async (body) => {
  const result = await postApi(`${baseUrl}/api/industrialvisits`, "post", body);
  return result;
});
export const industrialVisitDelete = createAsyncThunk(
  "industrialVisitDelete",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/industrialvisit/remove/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateIndustrialVisit = createAsyncThunk("updateIndustrialVisit", async (body) => {
  const result = await postApi(`${baseUrl}/api/industrialvisit/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getBanners = createAsyncThunk("getBanners", async () => {
    const result = await allApi(`${baseUrl}/api/banners`, "get" );
    return result;
  }
);
export const addBanner = createAsyncThunk("addBanner", async (body) => {
  const result = await postApiUploader(`${baseUrl}/api/banner/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteBanner = createAsyncThunk(
  "deleteBanner",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/banner/remove/${data}`,
      "delete"
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//Blog start
export const getBlogs = createAsyncThunk("getBlogs", async () => {
  const result = await allApi(`${baseUrl}/api/blog/list`, "get" );
  return result;
}
);
export const addBlog = createAsyncThunk("addBlog", async (body) => {
  const result = await postApiUploader(`${baseUrl}/api/blog/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateBlog = createAsyncThunk("updateBlog", async (body) => {
  const result = await postApiUploader(`${baseUrl}/api/blog/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteBlog = createAsyncThunk("deleteBlog", async (id) => {
    const result = await allApi(`${baseUrl}/api/blog/remove/${id}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
//Blog end
export const getCareerOpening = createAsyncThunk(
  "getCareerOpening",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/jobpost/list`,
      "get"
    );
    return result;
  }
);
export const addCareerOpening = createAsyncThunk("addCareerOpening", async (body) => {
  const result = await postApiUploader(`${baseUrl}/api/jobpost/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCareerOpening = createAsyncThunk("updateCareerOpening", async (body) => {
  const result = await postApiUploader(`${baseUrl}/api/jobpost/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteCareerOpening = createAsyncThunk(
  "deleteCareerOpening",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/jobpost/remove/${data}`,
      "delete"
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getDealershipOpening = createAsyncThunk("getDealershipOpening", async (body) => {
  const result = await postApi(`${baseUrl}/api/dealership/list`, "post", body);
  return result;
});
export const addDealershipOpening = createAsyncThunk("addDealershipOpening", async (body) => {
  const result = await postApi(`${baseUrl}/api/dealership/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateDealershipOpening = createAsyncThunk("updateDealershipOpening", async (body) => {
  const result = await postApi(`${baseUrl}/api/dealership/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteDealershipOpening = createAsyncThunk(
  "deleteDealershipOpening",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/dealership/remove/${data}`,
      "delete"
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// end website api's

//--------Categories ---------
export const getCategoryList = createAsyncThunk("getCategoryList", async (data) => {
  const result = await allApi(`${baseUrl}/api/category/list`, "get");
  return result;
});
export const addCategory = createAsyncThunk("addCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCategory = createAsyncThunk("updateCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//--------End Categories ---------
//--------Groups ---------
export const getGroupsList = createAsyncThunk("getGroupsList", async (data) => {
  const result = await allApi(`${baseUrl}/api/group/list`, "get");
  return result;
});
export const addGroup = createAsyncThunk("addGroup", async (body) => {
  const result = await postApi(`${baseUrl}/api/group/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateGroup = createAsyncThunk("updateGroup", async (body) => {
  const result = await postApi(`${baseUrl}/api/group/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//--------End Groups ---------
//-------- Products ---------
export const addNewProduct = createAsyncThunk("addNewProduct", async (body) => {
  const result = await postApiUploader(
    `${baseUrl}/auth/item`,"post",body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApiUploader(
    `${baseUrl}/auth/item/edit`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getProducts = createAsyncThunk("getProducts", async (data) => {
  const result = await allApi(`${baseUrl}/auth/item/list`, "get");
  return result;
});
//--------End Products ---------
//--------Contractor ---------
export const getContractorList = createAsyncThunk("getContractorList", async (data) => {
  const result = await allApi(`${baseUrl}/api/contractors`, "get");
  return result;
});
export const addContractor = createAsyncThunk("addContractor", async (body) => {
  const result = await postApi(`${baseUrl}/api/contractor/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateContractor = createAsyncThunk("updateContractor", async (body) => {
  const result = await postApi(`${baseUrl}/api/contractor/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getContractorAttendance = createAsyncThunk("getContractorAttendance", async (body) => {
  const result = await postApi(`${baseUrl}/api/attendances`, "post", body);
  return result;
});
export const contractorAttendanceUpdate = createAsyncThunk("contractorAttendanceUpdate", async (body) => {
  const result = await postApi(`${baseUrl}/api/attendance/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const contractorAttendanceAdd = createAsyncThunk("contractorAttendanceAdd", async (body) => {
  const result = await postApi(`${baseUrl}/api/attendance/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userWiseAttendance = createAsyncThunk("userWiseAttendance", async (body) => {
  const result = await postApi(`${baseUrl}/api/attendances/list`, "post", body);
  return result;
});
//--------Contractor end---------
export const createSalesPerson = createAsyncThunk(
  "createSalesPerson",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/salesman`,
      "post",
      body,
      "Create new salesperson."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateSalesPerson = createAsyncThunk(
  "updateSalesPerson",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/salesman/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getCreateSalesPerson = createAsyncThunk(
  "getCreateSalesPerson",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/salesman/list?state=${
        data?.state ? data?.state : ""
      }&page=${data?.page ? data?.page : "1"}&dispatch=${
        data?.dispatch ? data?.dispatch : ""
      }&asm=${data?.asm ? data?.asm : ""}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&distributorName=${
        data?.distributorName ? data?.distributorName : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
//without pagination
export const getSalesPersonWithoutPage = createAsyncThunk(
  "getSalesPersonWithoutPage",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/salesmans?dispatch=${
        data?.dispatch ? data?.dispatch : ""
      }&asm=${data?.asm ? data?.asm : ""}&status=${
        data?.userStatus ? data?.userStatus : ""
      }&distributorName=${data?.distributorName ? data?.distributorName : ""}`,
      "get"
    );
    return result;
  }
);
export const getASMPersonsWithOutPage = createAsyncThunk(
  "getASMPersonsWithOutPage",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/asms?dispatch=${
        data?.dispatch ? data?.dispatch : ""
      }&distributorName=${data?.distributorName ? data?.distributorName : ""}`,
      "get"
    );
    return result;
  }
);
export const getDispatchByWithOutPage = createAsyncThunk(
  "getDispatchByWithOutPage",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/dispatchs?salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getSelectedDistributorWithoutPage = createAsyncThunk(
  "getSelectedDistributorWithoutPage",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/distributors?state=${
        data?.state ? data?.state : ""
      }&asm=${data?.asm ? data?.asm : ""}&salesmanName=${
        data?.salesmanName ? data?.salesmanName : ""
      }`,
      "get"
    );
    return result;
  }
);
// asm monthly and product report
export const getASMReporting = createAsyncThunk(
  "getASMReporting",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/monthly/report?asm=${
        data?.asm ? data?.asm : ""
      }&userName=${data?.userName ? data?.userName : ""}&salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }&startMonth=${data?.startMonth ? data?.startMonth : ""}&endMonth=${
        data?.endMonth ? data?.endMonth : ""
      }&state=${data?.state ? data?.state : ""}&district=${
        data?.district ? data?.district : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}`,
      "get"
    );
    return result;
  }
);
export const getAsmProductReporting = createAsyncThunk(
  "getAsmProductReporting",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/asm/report/products?asm=${
        data?.asm ? data?.asm : ""
      }&userName=${data?.userName ? data?.userName : ""}&salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }&startMonth=${data?.startMonth ? data?.startMonth : ""}&endMonth=${
        data?.endMonth ? data?.endMonth : ""
      }&state=${data?.state ? data?.state : ""}&district=${
        data?.district ? data?.district : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}`,
      "get"
    );
    return result;
  }
);
// main report compant, state, district
export const getMainReports = createAsyncThunk(
  "getMainReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&category=${data?.category ? data?.category : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&divisionType=${data?.divisionType ? data?.divisionType : ''}`,
      "get"
    );
    return result;
  }
);
export const getMainProductReports = createAsyncThunk(
  "getMainProductReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/products/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&divisionType=${data?.divisionType ? data?.divisionType : ''}`,
      "get"
    );
    return result;
  }
);
export const getGroupWiseReports = createAsyncThunk(
  "getGroupWiseReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/group/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getCatWiseReports = createAsyncThunk(
  "getCatWiseReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/category/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const monthWiseOrderDeliverdMap = createAsyncThunk(
  "monthWiseOrderDeliverdMap",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/deliverorder/graph?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getCatWiseSale = createAsyncThunk(
  "getCatWiseSale",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/category/sale/list?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getGroupWiseSale = createAsyncThunk(
  "getGroupWiseSale",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/group/sale/list?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getTargetVsAchivementMonthly = createAsyncThunk(
  "getTargetVsAchivementMonthly",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/target/month/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${data?.asm ? data?.asm : ""}&dispatch=${data?.dispatchBy ? data?.dispatchBy : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
// orderd and deliverd order
export const getOrderdDeliverdMonthlyReports = createAsyncThunk(
  "getOrderdDeliverdMonthlyReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/all/order/monthly/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&asm=${
        data?.asm ? data?.asm : ""
      }&salesMan=${data?.so ? data?.so : ""}&year=${data?.year}`,
      "get"
    );
    return result;
  }
);
export const getOrderdDeliverdProductReports = createAsyncThunk(
  "getOrderdDeliverdProductReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/all/order/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&asm=${
        data?.asm ? data?.asm : ""
      }&salesMan=${data?.so ? data?.so : ""}&year=${data?.year}`,
      "get"
    );
    return result;
  }
);
// dispatchby report
export const getDispatchbyProductReports = createAsyncThunk(
  "getDispatchbyProductReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/superstokistby/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&superstokist=${
        data?.superstokist ? data?.superstokist : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}`,
      "get"
    );
    return result;
  }
);
//target-vs-achievement
export const getTargetVsAchievementReports = createAsyncThunk(
  "getTargetVsAchievementReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/target/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&superstokist=${
        data?.superstokist ? data?.superstokist : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&asm=${data?.asm ? data?.asm : ''
      }&salesMan=${data?.salesMan ? data?.salesMan : ''}&divisionType=${data?.divisionType ? data?.divisionType : ''}`,
      "get"
    );
    return result;
  }
);
//target-vs-achievement
export const getFocusTargetVsAchievementReports = createAsyncThunk(
  "getFocusTargetVsAchievementReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/focustarget/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&superstokist=${
        data?.superstokist ? data?.superstokist : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&asm=${data?.asm ? data?.asm : ''
      }&salesMan=${data?.salesMan ? data?.salesMan : ''}`,
      "get"
    );
    return result;
  }
);
export const getDispatchbyMonthlyReports = createAsyncThunk(
  "getDispatchbyMonthlyReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/monthly/report/bysuperstokist?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&superstokist=${
        data?.superstokist ? data?.superstokist : ""
      }&year=${data?.year}&endDate=${
        data?.endDate ? data?.endDate : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}`,
      "get"
    );
    return result;
  }
);
//superstockest orderd
export const getDispatchbyOrderProductReports = createAsyncThunk(
  "getDispatchbyOrderProductReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/superstokist/products/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getDispatchbyOrderMonthlyReports = createAsyncThunk(
  "getDispatchbyOrderMonthlyReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/superstokist/monthly/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&year=${
        data?.year
      }&endDate=${data?.endDate ? data?.endDate : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }`,
      "get"
    );
    return result;
  }
);
// salesMan report
export const getSoProductReports = createAsyncThunk(
  "getSoProductReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/sobyproduct/report?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }&year=${data?.year}`,
      "get"
    );
    return result;
  }
);
export const getSoMonthlyReports = createAsyncThunk(
  "getSoMonthlyReports",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/monthly/report/byslesman?startMonth=${
        data?.startMonth ? data?.startMonth : ""
      }&endMonth=${data?.endMonth ? data?.endMonth : ""}&state=${
        data?.state ? data?.state : ""
      }&district=${data?.district ? data?.district : ""}&salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }&year=${data?.year}`,
      "get"
    );
    return result;
  }
);
// end sales man report
export const getASMPersonsList = createAsyncThunk(
  "getASMPersonsList",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/asm/list?state=${data?.state ? data?.state : ""}&page=${
        data?.page ? data?.page : "1"
      }&dispatch=${data?.dispatch ? data?.dispatch : ""}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&distributorName=${
        data?.distributorName ? data?.distributorName : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const createASMPerson = createAsyncThunk(
  "createASMPerson",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/asm`,
      "post",
      body,
      "Create new ASMP."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateASMPerson = createAsyncThunk(
  "updateASMPerson",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/asm/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getSelectedSalesPerson = createAsyncThunk(
  "getSelectedSalesPerson",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/salesman/list?state=${
        data?.state ? data?.state : ""
      }&page=${data?.page ? data?.page : "1"}&asm=${
        data?.asm ? data?.asm : ""
      }&dispatch=${data?.dispatch ? data?.dispatch : ""}&district=${
        data?.district ? data?.district : ""
      }&status=${data?.userStatus ? data?.userStatus : ""}&distributorName=${
        data?.distributorName ? data?.distributorName : ""
      }&startDate=${data?.startDate ? data?.startDate : ""}&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const createDispatchBy = createAsyncThunk(
  "createDispatchBy",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/dispatch`,
      "post",
      body,
      "Created new Super Stockist."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getDispatchBy = createAsyncThunk("getDispatchBy", async (data) => {
  const result = await allApi(
    `${baseUrl}/api/dispatch/list?salesMan=${
      data?.salesMan ? data?.salesMan : ""
    }&state=${data?.state ? data?.state : ""}&district=${
      data?.district ? data?.district : ""
    }&page=${data?.page ? data?.page : "1"}&startDate=${
      data?.startDate ? data?.startDate : ""
    }&endDate=${
      data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
    }`,
    "get"
  );
  return result;
});
export const updateDispatchBy = createAsyncThunk(
  "updateDispatchBy",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/dispatch/edit`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getSalesmanDispatcBy = createAsyncThunk(
  "getSalesmanDispatcBy",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/salesman/bydispatch`,
      "post",
      body
    );
    return result;
  }
);
export const getOptionDispatchBy = createAsyncThunk(
  "getOptionDispatchBy",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/dispatch/list?salesMan=${
        data?.salesMan ? data?.salesMan : ""
      }&state=${data?.state ? data?.state : ""}&district=${
        data?.district ? data?.district : ""
      }&page=${data?.page ? data?.page : "1"}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const getMyProfile = createAsyncThunk("getMyProfile", async (data) => {
  const result = await allApi(`${baseUrl}/api/user/profile`, "get");
  return result;
});
export const editMyProfile = createAsyncThunk("editMyProfile", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/profile/edit`,
    "post",
    body,
    "Updated successfully."
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getSelectedDistributor = createAsyncThunk(
  "getSelectedDistributor",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/distributor/selected/list?mode=${
        data?.mode ? data?.mode : ""
      }&state=${data?.state ? data?.state : ""}&page=${
        data?.page ? data?.page : "1"
      }&asm=${data?.asm ? data?.asm : ""}&status=${data?.status ? data?.status : ""}&salesmanName=${
        data?.salesmanName ? data?.salesmanName : ""
      }&search=${data?.search ? data?.search : ""}&dispatch=${
        data?.dispatch ? data?.dispatch : ""
      }&district=${data?.district ? data?.district : ""}&startDate=${
        data?.startDate ? data?.startDate : ""
      }&endDate=${
        data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
      }`,
      "get"
    );
    return result;
  }
);
export const complaintRegister = createAsyncThunk(
  "complaintRegister",
  async (body) => {
    const result = await postApiProduct(
      `${baseUrl}/api/complaint`,
      "post",
      body,
      "We appreciate all Complaint. Thank you for bringing this issue to our attention."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(
    `${baseUrl}/auth/signin`,
    "post",
    body,
    "Your are login successfully."
  );
  await messages(result?.message, result?.status);
  return result;
});
export const careerUpdate = createAsyncThunk("careerUpdate", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/career/edit`,
    "post",
    body,
    "Updated successfully."
  );
  await messages(result?.message, result?.status);
  return result;
});
export const feedBackUpdate = createAsyncThunk(
  "feedBackUpdate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/feedback/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const complaintUpdate = createAsyncThunk(
  "complaintUpdate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/complaint/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const distributorUpdate = createAsyncThunk(
  "distributorUpdate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/distributor/edit`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const distributorCreate = createAsyncThunk(
  "distributorCreate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/distributor/select`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const createOrder = createAsyncThunk("createOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateOrder = createAsyncThunk("updateOrder", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/dispatch/order/update`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const targetAdd = createAsyncThunk("targetAdd", async (body) => {
  const result = await postApi(`${baseUrl}/api/target/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const targetUpdate = createAsyncThunk("targetUpdate", async (body) => {
  const result = await postApi(`${baseUrl}/api/target/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userWiseTarget = createAsyncThunk("userWiseTarget", async (body) => {
  const result = await postApi(`${baseUrl}/api/target/list`, "post", body);
  return result;
});
export const getOrderList = createAsyncThunk("getOrderList", async (data) => {
  //    data.getUsersOrderList = []
  const result = await allApi(
    `${baseUrl}/api/order/list?distributor=${
      data?.distributor ? data?.distributor : ""
    }&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${
      data?.asm ? data?.asm : ""
    }&companyName=${data?.companyName ? data?.companyName : ""}&status=${
      data?.userStatus ? data?.userStatus : ""
    }&page=${data?.page ? data?.page : "1"}&startDate=${
      data?.startDate ? data?.startDate : ""
    }&endDate=${
      data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
    }`,
    "get"
  );
  return result;
});
export const localSaleOrder = createAsyncThunk("localSaleOrder", async (data) => {
  //    data.getUsersOrderList = []
  const result = await allApi(
    `${baseUrl}/api/local/sale/order?distributor=${
      data?.distributor ? data?.distributor : ""
    }&salesMan=${data?.salesMan ? data?.salesMan : ""}&asm=${
      data?.asm ? data?.asm : ""
    }&companyName=${data?.companyName ? data?.companyName : ""}&status=${
      data?.userStatus ? data?.userStatus : ""
    }&page=${data?.page ? data?.page : "1"}&startDate=${
      data?.startDate ? data?.startDate : ""
    }&endDate=${
      data?.endDate ? moment(data?.endDate, "YYYY-MM-DD").add(1, "days") : ""
    }`,
    "get"
  );
  return result;
});
export const getOrderListRest = createAsyncThunk(
  "getOrderListRest",
  async (data) => {
    return [];
  }
);
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(
    `${baseUrl}/auth/logout`,
    "post",
    body,
    "Your are logout successfully."
  );
  await messages(result?.message, result?.status);
  return result;
});
//closing stock
export const closingStock = createAsyncThunk("closingStock", async (body) => {
  const result = await postApi(`${baseUrl}/api/stock/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const closingStockEdit = createAsyncThunk(
  "closingStockEdit",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/stock/edit  `, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getClosingStock = createAsyncThunk(
  "getClosingStock",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/stock/list?superstockistid=${data?.superstockistid}`,
      "get"
    );
    return result;
  }
);
const userReducer = createSlice({
  name: "personDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    // updateName(state, action){
    //     console.log("action===>", action.payload)
    //     state.name = action.payload
    // },
    // updateAge(state, action){
    //     state.age = action.payload
    // }
  },
  extraReducers: {
    [getCareerList.fulfilled]: (state, action) => {
      state.careerList = [...state.careerList, ...action.payload];
      state.careerListBlank = action.payload;
    },
    [getFeedBackList.fulfilled]: (state, action) => {
      state.userfeedBackList = [...state.userfeedBackList, ...action.payload];
      state.userfeedBackListBlank = action.payload;
    },
    [getContactList.fulfilled]: (state, action) => {
      state.getContactListData = [
        ...state.getContactListData,
        ...action.payload,
      ];
      state.userContactListBlank = action.payload;
    },
    [getContractorList.fulfilled]: (state, action) => {
      state.getContractorData = action.payload
    },
    [getCategoryList.fulfilled]: (state, action) => {
      state.getCategoryData = action.payload
    },
    [getGroupsList.fulfilled]: (state, action) => {
      state.getGroupsData = action.payload
    },
    [getContractorAttendance.fulfilled]: (state, action) => {
      state.getContractorAttendanceData = action.payload
    },
    [userWiseAttendance.fulfilled]: (state, action) => {
      state.userWiseAttendanceList = action.payload
    },
    [getClientReviewList.fulfilled]: (state, action) => {
      state.getClientReviewData = action.payload
    },
    [getIndustrialVisitList.fulfilled]: (state, action) => {
      state.getIndustrialVisitData = action.payload
    },
    [getBanners.fulfilled]: (state, action) => {
      state.getBannersList = action.payload
    },
    [getBlogs.fulfilled]: (state, action) => {
      state.getBlogList = action.payload?.list
    },
    [getCareerOpening.fulfilled]: (state, action) => {
      state.careerOpeningList = action.payload
    },
    [getDealershipOpening.fulfilled]: (state, action) => {
      state.dealershipOpeningList = action.payload
    },
    [getNewsLetterList.fulfilled]: (state, action) => {
      state.getNewsLetterData = action.payload
    },
    [getDistributorList.fulfilled]: (state, action) => {
      state.distributorList = [...state.distributorList, ...action.payload];
      state.distributorListBlank = action.payload;
    },
    [getStatesList.fulfilled]: (state, action) => {
      state.statesList = action.payload;
    },
    [getDepartmentDropDown.fulfilled]: (state, action) => {
      state.departmentDropDownList = action.payload?.list;
    },
    [getDepartmentWiseQ.fulfilled]: (state, action) => {
      state.departmentWiseQList = action.payload?.data;
    },
    [seniorStateDistributor.fulfilled]: (state, action) => {
      state.seniorStateDistributors = action.payload?.list;
    },
    [getDepartments.fulfilled]: (state, action) => {
      state.departmentList = action.payload?.list;
    },
    [getClosingStock.fulfilled]: (state, action) => {
      state.getClosingStockList = action.payload?.list;
    },
    [getCreateSalesPerson.fulfilled]: (state, action) => {
      state.getSalesPerson = [...state.getSalesPerson, ...action.payload];
      state.getSalesPersonMsg = action.payload;
    },
    [getSalesPersonWithoutPage.fulfilled]: (state, action) => {
      state.getSalesPersonWithoutPageList = action.payload;
    },
    [getASMPersonsWithOutPage.fulfilled]: (state, action) => {
      state.asmPersonsWithOutPage = action.payload;
    },
    [getDispatchByWithOutPage.fulfilled]: (state, action) => {
      state.dispatchByWithOutPage = action.payload;
    },
    [getSelectedDistributorWithoutPage.fulfilled]: (state, action) => {
      state.selectedDistributorWithoutPage = action.payload;
    },
    [getASMReporting.fulfilled]: (state, action) => {
      state.getASMReportingList = action.payload;
    },
    [getAsmProductReporting.fulfilled]: (state, action) => {
      state.getAsmProductReportingList = action.payload;
    },
    [getMainReports.fulfilled]: (state, action) => {
      state.getMainReportsList = action.payload;
    },
    [getMainProductReports.fulfilled]: (state, action) => {
      state.getMainProductReportsList = action.payload;
    },
    [getGroupWiseReports.fulfilled]: (state, action) => {
      state.getGroupWiseReportsList = action.payload;
    },
    [getCatWiseReports.fulfilled]: (state, action) => {
      state.getCatWiseReportsList = action.payload;
    },
    [monthWiseOrderDeliverdMap.fulfilled]: (state, action) => {
      state.monthWiseOrderDlvGrph = action.payload;
    },
    [getCatWiseSale.fulfilled]: (state, action) => {
      state.getCatWiseSaleList = action.payload;
    },
    [getGroupWiseSale.fulfilled]: (state, action) => {
      state.getGroupWiseSaleList = action.payload;
    },
    [getTargetVsAchivementMonthly.fulfilled]: (state, action) => {
      state.getTrgtVsAchiMonthlyList = action.payload;
    },
    [getOrderdDeliverdMonthlyReports.fulfilled]: (state, action) => {
      state.OrderdDeliverdMRpotList = action.payload;
    },
    [getOrderdDeliverdProductReports.fulfilled]: (state, action) => {
      state.OdrdDlvrProdtRpotList = action.payload;
    },
    [getSoProductReports.fulfilled]: (state, action) => {
      state.getSoProductReportsList = action.payload;
    },
    [getDispatchbyOrderMonthlyReports.fulfilled]: (state, action) => {
      state.getDispatchbyOrderMonthlyReportsList = action.payload;
    },
    [getDispatchbyOrderProductReports.fulfilled]: (state, action) => {
      state.getDispatchbyOrderProductReportsList = action.payload;
    },
    [getDispatchbyMonthlyReports.fulfilled]: (state, action) => {
      state.getDispatchbyMonthlyReportsList = action.payload;
    },
    [getDispatchbyProductReports.fulfilled]: (state, action) => {
      state.getDispatchbyProductReportsList = action.payload;
    },
    [getTargetVsAchievementReports.fulfilled]: (state, action) => {
      state.getTargetVsAchievementData = action.payload;
    },
    [getFocusTargetVsAchievementReports.fulfilled]: (state, action) => {
      state.getFocusTargetVsAchievementData = action.payload;
    },
    [getSoMonthlyReports.fulfilled]: (state, action) => {
      state.getSoMonthlyReportsList = action.payload;
    },
    [getASMPersonsList.fulfilled]: (state, action) => {
      state.getASMPersons = [...state.getASMPersons, ...action.payload];
      state.getASMPersonsMsg = action.payload;
    },
    [createASMPerson.fulfilled]: (state, action) => {
      state.createdASMPerson = action.payload;
    },
    [getSelectedSalesPerson.fulfilled]: (state, action) => {
      state.getSelectedSalesPersonList = action.payload;
    },
    [getDispatchBy.fulfilled]: (state, action) => {
      state.getDispatchByList = [...state.getDispatchByList, ...action.payload];
      state.getDispatchByListMsg = action.payload;
    },
    [getSalesmanDispatcBy.fulfilled]: (state, action) => {
      state.getSoDispatcByList = action.payload;
    },
    [getOptionDispatchBy.fulfilled]: (state, action) => {
      state.getOptionDispatchByList = action.payload;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.getProductsList = action.payload;
    },
    [getMyProfile.fulfilled]: (state, action) => {
      state.getMyProfileData = action.payload;
    },
    [getSelectedDistributor.fulfilled]: (state, action) => {
      state.getSelectedDistributorList = [
        ...state.getSelectedDistributorList,
        ...action.payload,
      ];
      state.getSelectedDistributorListMsg = action.payload;
    },
    [userWiseTarget.fulfilled]: (state, action) => {
      state.userWiseTargetList = [
        ...state.userWiseTargetList,
        ...action.payload,
      ];
      state.userWiseTargetMsg = action.payload;
    },
    [getOrderList.pending]: (state, action) => {
      //  state.getUsersOrderList = "Please wait....";
    },
    [getOrderListRest.fulfilled]: (state, action) => {
      state.getUsersOrderList = [];
      state.getLocalSaleOrderList=[];
      state.getDispatchByList = [];
      state.getSalesPerson = [];
      state.userfeedBackList = [];
      state.getASMPersons = [];
      state.getSelectedDistributorList = [];
      state.userWiseTargetList=[];
      state.getASMReportingList = [];
      state.distributorList = [];
      state.userQueryList = [];
      state.careerList = [];
      state.getMainReportsList = [];
      state.getMainProductReportsList = [];
      state.getSoProductReportsList = [];
      state.getSoMonthlyReportsList = [];
      state.getAsmProductReportingList = [];
      state.getDispatchbyMonthlyReportsList = [];
      state.getDispatchbyProductReportsList = [];
      state.getContactListData = [];
    },
    [getOrderList.fulfilled]: (state, action) => {
      state.getUsersOrderList = [...state.getUsersOrderList, ...action.payload];
      state.getOrderBlank = action.payload;
    },
    [localSaleOrder.fulfilled]: (state, action) => {
      state.getLocalSaleOrderList = [...state.getLocalSaleOrderList, ...action.payload];
      state.getLocalSaleBlank = action.payload;
    },
    [getDistributor.fulfilled]: (state, action) => {
      state.selectedDistributor = action.payload;
    },
    [userComplaintList.fulfilled]: (state, action) => {
      state.userQueryList = [...state.userQueryList, ...action.payload];
      state.userQueryListBlank = action.payload;
    },
    [getDistrictList.fulfilled]: (state, action) => {
      state.getDistrict = action.payload;
    },
    [careerRegister.pending]: (state, action) => {
      state.careerMsg = "Please Wait......";
    },
    [careerRegister.fulfilled]: (state, action) => {
      state.careerMsg = "Your form submited successfully.";
    },
    [careerRegister.rejected]: (state, action) => {
      state.careerMsg = "Error Pls Try Again.";
    },
    [distributorRegister.pending]: (state, action) => {
      state.distributorMsg = "Please Wait......";
    },
    [distributorRegister.fulfilled]: (state, action) => {
      localStorage.setItem("distributorAply", action.payload?.status);
      state.distributorMsg =
        "Thankyou for applying Distributorship. Assign person will contact you soon in our working hours.";
    },
    [distributorRegister.rejected]: (state, action) => {
      state.distributorMsg = "Error Pls Try Again.";
    },
    [feedBackRegister.pending]: (state, action) => {
      state.feedBackMsg = "Please Wait......";
    },
    [feedBackRegister.fulfilled]: (state, action) => {
      state.feedBackMsg =
        "We’re so happy to hear from you! Thank you for your valuable feedback.";
    },
    [feedBackRegister.rejected]: (state, action) => {
      state.feedBackMsg = "Error Pls Try Again.";
    },
    [createSalesPerson.pending]: (state, action) => {
      state.salesManMsg = "Please Wait......";
    },
    [createSalesPerson.fulfilled]: (state, action) => {
      state.salesManMsg = "Thankyou for create SalesMan.";
    },
    [createSalesPerson.rejected]: (state, action) => {
      state.salesManMsg = action?.error?.message;
    },
    [createDispatchBy.pending]: (state, action) => {
      state.createDispatchByMsg = "Please Wait......";
    },
    [createDispatchBy.fulfilled]: (state, action) => {
      state.createDispatchByMsg = "Thankyou for create SuperStockist.";
    },
    [createDispatchBy.rejected]: (state, action) => {
      state.createDispatchByMsg = action?.error?.message;
    },
    [addNewProduct.pending]: (state, action) => {
      state.addNewProductMsg = "Please Wait......";
    },
    [addNewProduct.fulfilled]: (state, action) => {
      state.addNewProductMsg = "Thankyou for create New Product.";
    },
    [addNewProduct.rejected]: (state, action) => {
      state.addNewProductMsg = action?.error?.message;
    },
    [editMyProfile.pending]: (state, action) => {
      state.editMyProfileMsg = "Please Wait......";
    },
    [editMyProfile.fulfilled]: (state, action) => {
      state.editMyProfileMsg = "Your profile is edit successfully.";
    },
    [editMyProfile.rejected]: (state, action) => {
      state.editMyProfileMsg = action?.error?.message;
    },
    [updateSalesPerson.pending]: (state, action) => {
      state.salesManUpdateMsg = "Please Wait......";
    },
    [updateSalesPerson.fulfilled]: (state, action) => {
      state.salesManUpdateMsg = "Thankyou for updating.";
    },
    [updateSalesPerson.rejected]: (state, action) => {
      state.salesManUpdateMsg = action?.error?.message;
    },
    [complaintRegister.pending]: (state, action) => {
      state.complaindMsg = "Please Wait......";
    },
    [complaintRegister.fulfilled]: (state, action) => {
      state.complaindMsg =
        "We appreciate all Complaint. Thank you for bringing this issue to our attention.";
    },
    [complaintRegister.rejected]: (state, action) => {
      state.complaindMsg = "Error Pls Try Again.";
    },
    [adminLogin.pending]: (state, action) => {
      state.loginMsg = "Please Wait......";
    },
    [adminLogin.fulfilled]: (state, action) => {
      localStorage.setItem("x-auth-token", action.payload.token);
      localStorage.setItem("userRole", action.payload.roll);
      localStorage.setItem("userName", action.payload.userName);
      state.loginMsg = "Your are login successfully.";
    },
    [adminLogin.rejected]: (state, action) => {
      state.loginMsg = "Error* Pls Try Again.";
    },
    [careerUpdate.pending]: (state, action) => {
      state.userUpdateMsg = "Please Wait......";
    },
    [careerUpdate.fulfilled]: (state, action) => {
      state.userUpdateMsg = "Updated successfully.";
    },
    [careerUpdate.rejected]: (state, action) => {
      state.userUpdateMsg = "Error Pls Try Again.";
    },
    [feedBackUpdate.pending]: (state, action) => {
      state.feedBackUpdateMsg = "Please Wait......";
    },
    [feedBackUpdate.fulfilled]: (state, action) => {
      state.feedBackUpdateMsg = "Updated successfully.";
    },
    [feedBackUpdate.rejected]: (state, action) => {
      state.feedBackUpdateMsg = "Error Pls Try Again.";
    },
    [complaintUpdate.pending]: (state, action) => {
      state.complaintUpdateMsg = "Please Wait......";
    },
    [complaintUpdate.fulfilled]: (state, action) => {
      state.complaintUpdateMsg = "Updated successfully.";
    },
    [complaintUpdate.rejected]: (state, action) => {
      state.complaintUpdateMsg = "Error Pls Try Again.";
    },
    [distributorUpdate.pending]: (state, action) => {
      state.distributorUpdateMsg = "Please Wait......";
    },
    [distributorUpdate.fulfilled]: (state, action) => {
      state.distributorUpdateMsg = "Updated successfully.";
    },
    [distributorUpdate.rejected]: (state, action) => {
      state.distributorUpdateMsg = "Error Pls Try Again.";
    },
    [distributorCreate.pending]: (state, action) => {
      state.distributorAddMsg = "Please Wait......";
    },
    [distributorCreate.fulfilled]: (state, action) => {
      state.distributorAddMsg = action?.error?.message;
    },
    [distributorCreate.rejected]: (state, action) => {
      state.distributorAddMsg = "Error Pls Try Again.";
    },
    [createOrder.pending]: (state, action) => {
      state.createOrderMsg = "Please Wait......";
    },
    [createOrder.fulfilled]: (state, action) => {
      state.createOrderMsg = "Your order created successfully.";
    },
    [createOrder.rejected]: (state, action) => {
      state.createOrderMsg = "Error Pls Try Again.";
    },
  },
});

export default userReducer.reducer;
