import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getASMPersonsWithOutPage,
  getSalesPersonWithoutPage,
  getDispatchByWithOutPage,
  getDistrictList,
  getOrderListRest,
  getStatesList,
  getTargetVsAchievementReports,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const TargetVsActivement = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [userName, setUserName] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [salesMan, setSalesMan] = useState();
  const [asm, setAsm] = useState();
  const [divisionType, setDivisionType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getStatesList());
    dispatch(
      getTargetVsAchievementReports({
        year: moment().format("YYYY"),
      })
    );
    dispatch(getDispatchByWithOutPage())
    dispatch(getASMPersonsWithOutPage())
    dispatch(getSalesPersonWithoutPage())
  }, []);
  const {statesList,getDistrict,asmPersonsWithOutPage, getSalesPersonWithoutPageList, dispatchByWithOutPage, getTargetVsAchievementData } =
    useSelector((state) => state.commonReducer);
  const handleSelectOption = (event) => {
    const option = event.target.options[event.target.selectedIndex].dataset;
    setSelectedOption(option);
    setUserName(event.target.value);
    // setSuperstokist()
  };
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
      <Row>
        <h3><b>Target vs Achievement</b></h3>
      </Row>
        <Form action={`${baseUrl}/api/targetcsv/report`} className="print-hidden">
          <Row className="mb-3">
                <Col md={2} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setState(e.target.value);
                        e.target.value.length > 1 &&
                            dispatch(getDistrictList(e.target.value));
                        }}
                        name="state"
                        className="form-control">
                        <option value={""}>Select State</option>
                        {!!statesList &&
                        statesList.map((data, index) => {
                            return (
                            <option key={index} value={data?.state}>
                                {data?.state}
                            </option>
                            );
                        })}
                    </Form.Control>
                </Col>
                <Col md={2} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setDistrict(e.target.value);
                        }}
                        name="district"
                        className="form-control"
                    >
                        <option value="">Select District</option>
                        {!!getDistrict &&
                        getDistrict.map((data, index) => {
                            return (
                            <option key={index} value={data}>
                                {data}
                            </option>
                            );
                        })}
                    </Form.Control>
                </Col>
                <Col md={2} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setStartMonth(e.target.value);
                        commonFunction(e.target.value);
                        }}
                        name="startMonth"
                        className="form-control"
                    >
                        <option value="">StartMonth</option>
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </Form.Control>
                </Col>
                <Col md={2} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setEndMonth(e.target.value);
                        }}
                        name="endMonth"
                        className="form-control"
                    >
                        <option value="">EndMonth</option>
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </Form.Control>
                </Col>
                <Col md={4} sm={12}>
                    <Form.Control
                        as="select"
                        value={divisionType}
                        onChange={async (e) => {
                          setDivisionType(e.target.value);
                        }}
                        name="divisionType"
                        className="form-control"
                    >
                        <option value="">Select Division</option>
                        <option value="2">New Division</option>
                    </Form.Control>
                </Col>
          </Row>
          <Row className="mt-1">
          <Col md={2} sm={12}>
              <label>Select ASM</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setAsm(e.target.value);
                }}
                name="asm"
                className="form-control"
              >
                <option value="">Select ASM</option>
                {!!asmPersonsWithOutPage &&
                  asmPersonsWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select SO</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setSalesMan(e.target.value);
                }}
                name="so"
                className="form-control"
              >
                <option value="">Select SO</option>
                {!!getSalesPersonWithoutPageList &&
                  getSalesPersonWithoutPageList.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
            <label>Select DispatchBy</label>
                  <Form.Control
                    as="select"
                    onChange={handleSelectOption}
                    name="superstokist"
                    className="form-control"
                  >
                    <option value={"All"}>Select DispatchBy</option>
                    {!!dispatchByWithOutPage &&
                      dispatchByWithOutPage.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data?._id}
                          >
                            {data?.dispatch}
                          </option>
                        );
                      })}
                  </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={async() => {
                  setLoading(true)
                  const apiResp = await dispatch(
                    getTargetVsAchievementReports({
                      superstokist: userName,
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm,
                      divisionType
                    })
                  );
                  if(apiResp?.payload){
                    setLoading(false)
                   }
                }}
                disabled={loading ? true : false}
              >
                 {loading ? <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner> : "Submit"}
              </Button>
            </Col>
            <Col md={1} className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md={4} className="mt-4">
              <Button variant="warning" type="submit">
                Export Report
              </Button>
            </Col>
            <Col md={6} className="text-align-end mt-4">
              <Button onClick={() => window.print()}>
                <i className="bi bi-printer-fill"></i>
              </Button>
            </Col>
          </Row>
        </Form>
        <table className="table table-scrolling mt-2">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              {!!getTargetVsAchievementData &&
                !!getTargetVsAchievementData?.products &&
                Object.entries(getTargetVsAchievementData?.products).map(
                  ([key, value]) => <th scope="col">{value}</th>
                )}
              <th scope="col" className="text-white bg-danger">Other Target</th>
              <th scope="col" className="text-white bg-success">Other Ach.</th>
              <th scope="col" className="text-white bg-danger">Focus Target</th>
              <th scope="col" className="text-white bg-success">Focus Ach.</th>
              <th scope="col" className="text-white bg-danger">Total Target</th>
              <th scope="col" className="text-white bg-success">Total Ach.</th>
            </tr>
          </thead>
          <tbody>
            {!!getTargetVsAchievementData &&
              !!getTargetVsAchievementData?.productWiseOrder &&
              Object.values(
                getTargetVsAchievementData?.productWiseOrder
              ).map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.firmName}</td>
                      <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                      <td>{data?.salesman}</td>
                      <td>{data?.asm}</td>
                      {!!getTargetVsAchievementData?.products &&
                        Object.keys(
                          getTargetVsAchievementData?.products
                        ).map(function (key) {
                          return(
                            <td>
                                <tr>
                                    <td>{data[key]?.Target}</td>
                                    <td className={`text-white ${data[key]?.Target > data[key]?.Achievement ? `bg-danger` : `bg-success`}`}>{data[key]?.Achievement}</td>
                                </tr>
                            </td>
                          )
                        })}
                        <td>{data?.otherTarget}</td>
                        <td>{data?.OtherAchiev}</td>
                        <td>{data?.focusTarget}</td>
                        <td>{data?.focusAchiev}</td>
                        <td>{data?.cbtotal?.Target}</td>
                        <td>{data?.cbtotal?.Achievement}</td>
                      {/* <td>
                        <tr>
                            <td>{data?.cbtotal?.Target}</td>
                            <td className={`text-white ${data?.cbtotal?.Target > data?.cbtotal?.Achievement ? `bg-danger` : `bg-success`}`}>{data?.cbtotal?.Achievement}</td>
                        </tr>
                      </td> */}
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
              {!!getTargetVsAchievementData &&
                !!getTargetVsAchievementData?.total &&
                Object.keys(getTargetVsAchievementData?.products).map(
                  function (key) {
                    return (
                      <td>
                        <tr>
                            <td><b>{getTargetVsAchievementData?.total[key]?.Target}</b></td>
                            <td className={`text-white ${getTargetVsAchievementData?.total[key]?.Target > getTargetVsAchievementData?.total[key]?.Achievement ? `bg-danger` : `bg-success`}`}><b>{getTargetVsAchievementData?.total[key]?.Achievement}</b></td>
                        </tr>
                      </td>
                    );
                  }
                )}
                 <td className="text-white bg-danger">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.otherTarget}
                </td>
                <td className="text-white bg-success">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.otherAchiev}
                </td>
                <td className="text-white bg-danger">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.focusTarget}
                </td>
                <td className="text-white bg-success">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.focusAchiev}
                </td>
                <td className="text-white bg-danger">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.cbTotel?.Target}
                </td>
                <td className="text-white bg-success">
                  {!!getTargetVsAchievementData &&
                        getTargetVsAchievementData?.total?.cbTotel?.Achievement}
                </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TargetVsActivement;
